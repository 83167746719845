import { ComponentPropsWithoutRef } from 'react';
import NextImage from 'next/image';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import useSWR from 'swr';
import { AppsRecommendedDocument } from '@/generated/contentful';
import { client } from '@/lib/contentful/client';
import { isRichText, serializeCacheKey } from '@/lib/contentful/utils';
import { shouldAppReviewTemporarilyHidden } from '@/utils/apps/constants';
import { CardCarousel } from '@/components/CardCarousel';
import { Carousel } from '@/components/Carousel';
import { Link } from '@/components/CustomLink';
import { Image } from '@/components/Image';
import { Warning } from '@/components/Warning';
import { useEntry } from '../App.hooks';
import { ReviewStars } from '../AppCard/ReviewStars';
type Props = Omit<ComponentPropsWithoutRef<typeof CardCarousel>, 'cards'>;
export function AppsRecommendedCarousel({
  skip,
  itemsShown,
  autoplay,
  className
}: Props) {
  const entry = useEntry();
  const {
    data
  } = useSWR('data' in entry && serializeCacheKey('appSimilarApps', {
    appId: entry.data?.sys?.id ?? null
  }), () => client().request(AppsRecommendedDocument, {
    where: {
      appRegistryId_in: 'data' in entry ? entry.data?.similarApps?.filter(Boolean) : null
    }
  }));
  if ('error' in entry) {
    return <Warning className={className}>{entry.error}</Warning>;
  }
  return <Carousel className={className} autoplay={autoplay} itemsShown={itemsShown} skip={skip} loop={true} data-sentry-element="Carousel" data-sentry-component="AppsRecommendedCarousel" data-sentry-source-file="AppsRecommendedCarousel.tsx">
			{data?.marketplaceAppCollection?.items?.map(app => {
      if (!app) return null;
      return <div className={`min-w-[250px] lg:min-w-[200px] lg:min-w-auto bg-white shadow-lg rounded-xl relative p-6`} key={app.sys.id}>
						<Link href={`/apps/${app.slug}/`} className="grid gap-2">
							{/* Image */}
							{app.logoIcon && <NextImage src={app.logoIcon?.startsWith('//') ? `https:${app.logoIcon}` : app.logoIcon!} alt={app.name || ''} width={40} height={40} sizes="40px" className="mb-6 object-contain" />}

							<div className="grid gap-2">
								{app.name && <h3 className="text-bc-black text-xl font-bold">{app.name}</h3>}

								{isRichText(app.summary) && <p className="text-gray-700 line-clamp-3">
										{documentToPlainTextString(app.summary.json)}
									</p>}
							</div>

							<div>
								<h2 className="my-3 text-bc-black font-medium">Pricing</h2>
								<ul className="text-gray-700 line-clamp-3 grid gap-1">
									<li>
										Upfront fee:
										{!app?.upfrontPrice ? ' Free' : ` $${app?.upfrontPrice}`}
									</li>
									<li>Recurring fee: {`$${app?.monthlyPrice ?? 0}`}/mo.</li>
								</ul>
							</div>

							{!shouldAppReviewTemporarilyHidden(app.name) && <div>
									{/* Review Stars */}
									{!!app.rating && <ReviewStars rating={app.rating} className="mt-6" />}
									{typeof app.reviewCount === 'number' && app.reviewCount > 0 ? <span className="mt-1 block text-xs text-gray-500">
											{app.reviewCount} reviews
										</span> : <span className="mt-3 block text-xs text-gray-500">No reviews yet</span>}
								</div>}

							{/* App tier icon */}
							{app.appTier?.logo?.url && <Image src={app.appTier.logo.url} alt={app.appTier.name || 'App Tier'} width={app.appTier.logo.width || 32} height={app.appTier.logo.height || 32} className="h-8 w-min object-contain absolute bottom-6 right-6" />}
						</Link>
					</div>;
    })}
		</Carousel>;
}